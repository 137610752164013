<template>
  <div class="container">
    <div class="content">
      <div class="operation">
        <el-button type="primary" icon="el-icon-plus" @click="handleCreate">新建</el-button>
        <el-button type="danger" plain @click="batchDelete">删除</el-button>
      </div>
      <div class="rule">
        <el-checkbox
          v-model="rule.allUse"
          style="margin-right: 10px;"
          @change="handleCheckedChange"
        ></el-checkbox>
        启用全场满{{ rule.freight }}元包邮
        <el-popover class="tp" placement="bottom" width="220" v-model="visible">
          <div class="tp-header">金额修改</div>
          <div class="tp-input">
            <el-input placeholder="请输入金额" v-model="rule.freight">
              <template slot="prepend">¥</template>
            </el-input>
          </div>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false"
              >取消</el-button
            >
            <el-button type="primary" size="mini" @click="handleConfirmUpdate"
              >确定</el-button
            >
          </div>
          <span slot="reference" class="update">金额修改</span>
        </el-popover>
        <field
          label="差值输入："
          :asterisk="true"
          labelWidth="160px"
          style="padding-top: 15px;"
        >
          <el-input
            v-model="rule.minFreight"
            placeholder="请输入金额"
            controls-position="right"
            @blur="handleDifChange"
          ></el-input>
        </field>
      </div>
      <el-table
        ref="multipleTable"
        :data="templates"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="name" label="模版名称"> </el-table-column>
        <el-table-column prop="type" label="计费方式">
          <template slot-scope="scope">
            <span v-for="item in scope.row.roleList" :key="item.id">{{
              item.roleName
            }}</span>
            <span>{{ scope.row.type === "WEIGHT" ? "重量" : "件数" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{fmtDate(scope.row.createdDate)}}
          </template>
        </el-table-column>
        <el-table-column label="编辑时间">
          <template slot-scope="scope">
            {{fmtDate(scope.row.lastModifiedDate)}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
            <el-button type="text" @click="deleteOne(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageOptions"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import { pageOptions } from '@/utils/config'
import * as templateService from '@/api/freightTemplates'
import moment from 'moment'

export default {
  data () {
    return {
      search: '',
      templates: [],
      selected: [],
      total: 100,
      pageOptions: pageOptions,
      currentPage: 1,
      visible: false,
      rule: {
        id: null,
        allUse: false,
        freight: null,
        minFreight: null
      }
    }
  },
  created () {
    this.fetchData()
    this.fetchRule()
  },
  methods: {
    fmtDate (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    fmt (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    async fetchRule () {
      const res = await templateService.getConfig()
      res.freight = this._pennyToYuan(res.freight)
      res.minFreight = this._pennyToYuan(res.minFreight)
      this.rule = R.pick(['allUse', 'freight', 'minFreight', 'id'])(res)
    },
    async updateConfig () {
      try {
        const data = R.clone(this.rule)
        data.freight = this._yuanToPenny(data.freight)
        data.minFreight = this._yuanToPenny(data.minFreight)
        await templateService.updateConfig(data)
        this.$notify.success('修改成功！')
      } catch (err) {}
    },
    handleDifChange () {
      this.updateConfig()
    },
    handleCheckedChange () {
      this.updateConfig()
    },
    handleConfirmUpdate () {
      this.visible = false
      this.updateConfig()
    },
    async fetchData () {
      try {
        const res = await templateService.list()
        this.templates = res.list
        this.total = res.totalElements
      } catch (err) {
        // handle error
      }
    },
    getItem (items) {
      return items.map(o => o.name).join(',')
    },
    handleCreate () {
      this.$router.push({ path: '/freightTemplates/create' })
    },
    handleTabChange (tab) {
    },
    edit (id) {
      this.$router.push(`/freightTemplates/edit/${id}`)
    },
    handleSelectionChange (val) {
      this.selected = val
    },
    async deleteOne (id) {
      try {
        await templateService.deleteById(id)
        this.fetchData()
      } catch (err) {
        this.$message.error('删除用户发生错误')
      }
    },
    async batchDelete () {
      try {
        const selected = this.selected
        await Promise.all(selected.map(o => this.deleteOne(o.id)))
        this.fetchData()
      } catch (err) {
        // handle error
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.content {
  .operation {
    padding: 20px;
  }
  margin-top: 10px;
  background: #ffffff;
  padding: 10px;
}
.pagination {
  margin-top: 30px;
  text-align: center;
}

.rule {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.update {
  cursor: pointer;
  color: #3270ff;
  margin-left: 10px;
}

.tp {
  &-header {
    padding: 5px;
    width: 100%;
    border-bottom: 1px solid #d9e0f0;
  }
  &-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
